<template>
  <b-card>
    <h1>ciaooo funziona perfavore</h1>
    <b-row>
      <b-col
        cols="8"
        class="mb-2"
      >
        <b-form-group>
          <label for="InputProfile">Nome Profilo</label>
          <b-form-input
            id="InputProfile"
            v-model="nameProfile"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="checkbox-group-1">CRUD</label>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selectedCheck"
            :options="optionsCheck"
            name="flavour-1"
          />
          <label for=""><strong>Selected:</strong>{{ selectedCheck }}</label>
        </b-form-group>
      </b-col>
      <b-col
        cols="8"
        class="mb-2"
      >
        <b-form-group>
          <label for="descriptionProfile">Descrizione</label>
          <b-form-textarea
            id="descriptionProfile"
            v-model="descriptionProfile"
            placeholder="Textarea"
            rows="3"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col
        cols="4"
        class="mb-4"
      >
        <b-form-select
          v-model="selected"
          :options="options"
          v-on="changeTable()"
        />
        Selected: {{ selected }}
      </b-col>
    </b-row>
    <b-table
      v-if="selected !== null"
      responsive
      :items="items"
      :fields="fields"
      class="mb-0"
    >
      <template #cell(visible)="data">
        <b-form-radio
          v-model="data.item.permission"
          :name="data.item.id"
          value="0"
        />

      </template>
      <template #cell(notVisible)="data">
        <b-form-radio
          v-model="data.item.permission"
          :name="data.item.id"
          value="1"
        />

      </template>
      <template #cell(write)="data">
        <b-form-radio
          v-model="data.item.permission"
          :name="data.item.id"
          value="2"
        />

      </template>

    </b-table>
    <b-button
      v-if="selected !== null"
      @click="submitPermission(items)"
    >
      Cambia Permessi
    </b-button>
    <div>
      <h2>Hai Aggiunto</h2>
      {{ form }}
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BTable, BFormSelect, BFormRadio, BButton, BFormTextarea, BFormGroup, BFormInput, BFormCheckboxGroup, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormSelect,
    BCard,
    BFormRadio,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
  },
  data() {
    return {
      descriptionProfile: '',
      nameProfile: '',
      selected: null,
      selectedCheck: [],
      profile: 'questo è il profilo',
      description: 'questa è la descrizione',
      options: [
        { value: null, text: 'Please select an option', disabled: true },
        { value: 'customers', text: 'Clienti' },
        { value: 'patients', text: 'Pazienti' },
        { value: 'suppliers', text: 'Fornitori' },
      ],
      optionsCheck: [
        { text: 'Read', value: 'read' },
        { text: 'Print', value: 'print' },
        { text: 'Create', value: 'create' },
        { text: 'Update', value: 'update' },
        { text: 'Delete', value: 'delete' },
      ],
      fields: [
        'id',
        'campo',
        'visible',
        'notVisible',
        'write',
      ],
      patients: [
        { text: 'Read', value: 'read' },
        { text: 'Print', value: 'print' },
        { text: 'Create', value: 'create' },
        { text: 'Update', value: 'update' },
        { text: 'Delete', value: 'delete' },
        {
          id: '1',
          campo: 'NomePatients',
          permission: 0,
        },
        {
          id: '2',
          campo: 'CognomePatients',
          permission: 1,
        },
        {
          id: '3',
          campo: 'Data di NascitaPatients',
          permission: 2,
        },
        {
          id: '4',
          campo: 'SessoPatients',
          permission: 1,
        },
        {
          id: '5',
          campo: 'CittàPatients',
          permission: 2,
        },
        {
          id: '6',
          campo: 'ProvinciaPatients',
          permission: 1,
        },
        {
          id: '7',
          campo: 'Codice FiscalePatients',
          permission: 1,
        },
      ],
      customers: [
        {
          id: '1',
          campo: 'NomeCustomer',
          permission: 1,
        },
        {
          id: '2',
          campo: 'CognomeCustomer',
          permission: 2,
        },
        {
          id: '3',
          campo: 'Data di Nascita Customer',
          permission: 0,
        },
        {
          id: '4',
          campo: 'SessoCustomer',
          permission: 2,
        },
        {
          id: '5',
          campo: 'CittàCustomer',
          permission: 0,
        },
        {
          id: '6',
          campo: 'ProvinciaCustomer',
          permission: 0,
        },
        {
          id: '7',
          campo: 'Codice Fiscale Customer',
          permission: 2,
        },
      ],
      suppliers: [
        {
          id: '1',
          campo: 'NomeSuppliers',
          permission: 0,
        },
        {
          id: '2',
          campo: 'CognomeSuppliers',
          permission: 0,
        },
        {
          id: '3',
          campo: 'Data di Nascita Suppliers',
          permission: 2,
        },
        {
          id: '4',
          campo: 'SessoSuppliers',
          permission: 1,
        },
        {
          id: '5',
          campo: 'CittàSuppliers',
          permission: 0,
        },
        {
          id: '6',
          campo: 'ProvinciaSuppliers',
          permission: 2,
        },
        {
          id: '7',
          campo: 'Codice Fiscale Suppliers',
          permission: 1,
        },
      ],
      items: [],
      form: [],
    }
  },
  created() {
    // this.patients = this.items
    // console.log('patients', this.patients)
  },
  methods: {
    submitPermission(form) {
      const myvar = form
      this.form.push(...myvar, this.nameProfile, this.descriptionProfile, this.selectedCheck)
    },
    changeTable() {
      if (this.selected === 'customers') {
        this.items = this.customers
      } else if (this.selected === 'suppliers') {
        this.items = this.suppliers
      } else if (this.selected === 'patients') {
        this.items = this.patients
      }
    },
  },
}
</script>
